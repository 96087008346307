import { styled } from '@mui/material/styles';
import { finanzcheck } from '@finanzcheck/teal-mui-theme';
import { SPACE3 } from 'lib/constants/layout';

const { colors } = finanzcheck;
const colorMap: { [x: string]: { text: string; shadow: string } } = {
  light: {
    text: colors.text.white,
    shadow: '0 0 8px rgba(0, 0, 0, 0.4)',
  },
  dark: {
    text: colors.text.blue,
    shadow: 'none',
  },
};

interface HeadlineWrapperProps {
  showOnLapUp?: boolean;
}

// We are scaling down extra long lines in the hope, it will fit
export const getScaledSizeOnCharacterCount = (
  defaultSize: number,
  characterCount: number,
  ext = '',
) => {
  let factor = 1;

  if (characterCount > 50) {
    factor = 0.5;
  } else if (characterCount > 40) {
    factor = 0.6;
  } else if (characterCount > 35) {
    factor = 0.7;
  }

  const scaledSize = defaultSize * factor;
  return `${scaledSize}${ext}`;
};

const HeadlineWrapper = styled('div')<HeadlineWrapperProps>`
  height: 128px;
  max-width: 60%;
  min-height: 132px;
  overflow: hidden;
  text-align: left;
  margin-left: 1rem;

  ${(props) => props.theme.breakpoints.up('xs')} {
    height: 144px;
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    height: auto;
    max-width: 65%;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 70%;
    margin-left: -13%;
    text-align: center;
    ${(props) => !props.showOnLapUp && { display: 'none' }}
  }
`;

interface HeadlineElementProps {
  textStyle: string;
  charcount: number;
  children?: React.ReactNode;
}
export const HeadlineElement = styled('h1')<HeadlineElementProps>(
  ({ theme, charcount, textStyle }) => ({
    color: colorMap.light.text,
    fontSize: getScaledSizeOnCharacterCount(1.5, charcount, 'rem'),
    fontWeight: 'bold',
    lineHeight: getScaledSizeOnCharacterCount(2, charcount, 'rem'),
    margin: `${SPACE3} 0 0`,
    textShadow: colorMap.light.shadow,

    [theme.breakpoints.up('sm')]: {
      fontSize: getScaledSizeOnCharacterCount(2, charcount, 'rem'),
      lineHeight: getScaledSizeOnCharacterCount(56, charcount, 'px'),
    },

    [theme.breakpoints.up('md')]: {
      color: colorMap[textStyle].text,
      display: 'block',
      textShadow: colorMap[textStyle].shadow,
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: getScaledSizeOnCharacterCount(2.5, charcount, 'rem'),
    },
  }),
);

interface SublineProps {
  textStyle: string;
  charcount: number;
  children?: React.ReactNode;
}
const Subline = styled('h2')<SublineProps>(
  ({ theme, textStyle, charcount }) => ({
    color: colorMap.light.text,
    fontSize: getScaledSizeOnCharacterCount(1.125, charcount, 'rem'),
    lineHeight: 1.35,
    margin: 0,
    textShadow: colorMap.light.shadow,

    [theme.breakpoints.up('sm')]: {
      fontSize: getScaledSizeOnCharacterCount(1.5, charcount, 'rem'),
      lineHeight: 1.35,
    },

    [theme.breakpoints.up('md')]: {
      color: colorMap[textStyle].text,
      display: 'block',
      textShadow: colorMap[textStyle].shadow,
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: getScaledSizeOnCharacterCount(2, charcount, 'rem'),
    },
  }),
);

interface HeadlineExclusiveBadgesProps {
  headline: string;
  subline?: string;
  headlineStyle: string;
  showOnLapUp?: boolean;
}
export const HeadlineExclusiveBadges = ({
  headline,
  subline = '',
  headlineStyle = 'light',
  showOnLapUp = true,
}: HeadlineExclusiveBadgesProps) => {
  return (
    <HeadlineWrapper showOnLapUp={showOnLapUp}>
      <HeadlineElement textStyle={headlineStyle} charcount={headline.length}>
        {headline}
      </HeadlineElement>
      <Subline textStyle={headlineStyle} charcount={subline.length}>
        {subline}
      </Subline>
    </HeadlineWrapper>
  );
};
