import { styled } from '@mui/material/styles';

import { getTwoThirdsLegalText } from 'lib/legaltext/legalTextHelper';
import { useBestOffer } from 'contexts/BestOfferContext';
import { useLoanSelection } from 'contexts/LoanSelectionContext';

export const VariantLegalText = styled('p')(({ theme }) => ({
  fontSize: 12,
  lineHeight: '20px',
  color: theme.palette.grey[800],
  width: '100%',
  margin: 0,
  marginBottom: 36,
  marginTop: 16,

  [theme.breakpoints.up('md')]: {
    marginBottom: 36,
    paddingRight: 16,
    width: '50%',
  },
}));

export const LegalTextExclusiveBadges = () => {
  const { loanSelection } = useLoanSelection();
  const { bestOffer } = useBestOffer();

  const text = getTwoThirdsLegalText(bestOffer, loanSelection);

  return (
    <VariantLegalText>
      {text && (
        <>
          <b>¹Beispielrechnung gemäß PAngV</b> {text}
        </>
      )}
    </VariantLegalText>
  );
};
