/**
 * A set of helper in conjuntion with the Image Sizing fill option of the
 * next image component. There you have to set the height and width of the
 * image. This helper will help you to calculate the other dimension based
 * on the original image size.
 */
export const getAspectRatioFromSize = (height: number, width: number) => {
  return width / height;
};

export const getHeightFromWidth = (
  originalHeight: number,
  originalWidth: number,
  targetWidth: number,
) => {
  const aspectRatio = getAspectRatioFromSize(originalHeight, originalWidth);
  return targetWidth / aspectRatio;
};

export const getWidthFromHeight = (
  originalHeight: number,
  originalWidth: number,
  targetHeight: number,
) => {
  const aspectRatio = getAspectRatioFromSize(originalHeight, originalWidth);
  return targetHeight * aspectRatio;
};
