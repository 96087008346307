import { styled } from '@mui/material/styles';

import { SPACE2_RAW } from 'lib/constants/layout';
import { Seal } from './Seal';
import { getWidthFromHeight } from 'lib/helpers/imageSizer';

import { ISealFields, ISeal } from 'types/generated/contentful';
import { AssetDetails } from 'contentful';

const IMAGE_HEIGHT_RAW = 74;
const CONTENT_HEIGHT = `${IMAGE_HEIGHT_RAW + 2 * SPACE2_RAW}px`;

const Wrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  boxSizing: 'border-box',
  display: 'flex',
  flex: '1 0 50%',
  flexFlow: 'row-reverse wrap',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: '10px 4% 0',
  widht: '100%',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
    height: CONTENT_HEIGHT,
  },
}));

const SealWrapper = styled('div')`
  margin-right: 10px;
`;

const SealLink = styled('a')`
  display: block;
`;

interface SealsProps {
  seals: ISeal[];
}
export const Seals = (props: SealsProps) => {
  const { seals } = props;

  return (
    <Wrapper>
      {seals.map((entry) => {
        const { image, description, link } = entry.fields as ISealFields;
        const url = image.fields.file?.url;
        const imageDetails = image.fields.file?.details as AssetDetails;

        const originalWidth = imageDetails.image?.width || IMAGE_HEIGHT_RAW;
        const originalHeight = imageDetails.image?.height || IMAGE_HEIGHT_RAW;
        const scaledWidth = getWidthFromHeight(
          originalHeight,
          originalWidth,
          IMAGE_HEIGHT_RAW,
        );

        return (
          <SealWrapper key={entry.sys.id}>
            <SealLink href={link} target="_blank" rel="noreferrer">
              <Seal
                url={url}
                alt={description}
                height={IMAGE_HEIGHT_RAW}
                width={scaledWidth}
              />
            </SealLink>
          </SealWrapper>
        );
      })}
    </Wrapper>
  );
};
