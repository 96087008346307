import Image from 'next/image';
import { styled } from '@mui/material/styles';
import { finanzcheck } from '@finanzcheck/teal-mui-theme';

import { SPACE2 } from 'lib/constants/layout';
const { colors } = finanzcheck;

export const BulletPointsWrapper = styled('div')`
  display: block;
`;

const ListWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'textColor',
})<{ textColor: string }>(({ theme, textColor }) => ({
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: textColor,
  display: 'none',
  fontWeight: 'bold',
  listStylePosition: 'inside',

  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

const List = styled('ul')`
  list-style: none;
  padding: 0;
`;

const ListItem = styled('li')`
  align-items: center;
  display: flex;
  max-height: 48px; /* IE11 fix */

  & > svg {
    max-height: 24px; /* IE11 fix */
  }
`;

const TextWrapper = styled('div')`
  margin-left: ${SPACE2};
`;

export interface BulletPointsProps {
  bulletPoints?: string[];
  image?: string;
  textColor?: string;
}
export const BulletPoints: React.FC<BulletPointsProps> = ({
  image = '/static/icons/checkCircle.svg',
  bulletPoints,
  textColor = colors.text.blue,
}) => {
  return (
    <BulletPointsWrapper>
      {bulletPoints && (
        <ListWrapper textColor={textColor}>
          <List>
            {bulletPoints.map((entry) => (
              <ListItem key={entry}>
                <Image
                  src={image}
                  alt="check seal"
                  width="24"
                  height="24"
                  loading="lazy"
                  priority={false}
                />
                <TextWrapper>{entry}</TextWrapper>
              </ListItem>
            ))}
          </List>
        </ListWrapper>
      )}
    </BulletPointsWrapper>
  );
};
